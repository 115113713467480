import React, { useEffect, useState } from 'react';
import { Chart } from 'react-charts';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styled from 'styled-components';
import trianglify from 'trianglify';

dayjs.extend(customParseFormat)

const AppStyled = styled.div`
    padding: 20px;
`
const ChartWrapper = styled.div`
    width: calc(50vw - 30px);
    height: 400px;      
`;
const ChartsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;    
    @media (max-width: 768px) {
        flex-direction: column;
        ${ChartWrapper}{
            width: 100%;
        }
    }
`;
const Card = styled.div`
    text-align: center;
    background-color: rgba(255,255,255,0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`
const Header = styled.h2`
    text-shadow: 1px 1px 1px rgba(255,255,255,0.4);
`
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
const App = () => {
    const [data, setData] = useState(null);
    const { width } = useWindowDimensions();
    const ref = React.useRef();
    useEffect(() => {
        fetch('/indexes')
            .then((res) => res.json())
            .then((res) => setData(res));
    }, []);
    const stocksScore = React.useMemo(() => {
        return Math.floor(data?.cnn?.results?.fear_and_greed?.score);
    }, [data])
    const cryptoScore = React.useMemo(() => {
        return data?.crypto?.results?.data?.datasets[0]?.data.pop()
    }, [data])

    const getCryptoData = React.useCallback(data => {
        return data?.crypto?.results?.data?.datasets[0]?.data;
    }, [])
    const getStocksData = React.useCallback(data => {
        return data?.cnn?.results?.fear_and_greed_historical?.data;
    }, [])
    const cryptoData = React.useMemo(() => ([
        {
            label: 'crypto',
            data: getCryptoData(data) ? getCryptoData(data).map((item, index) => {
                return { score: parseInt(item), date: dayjs(data.crypto.results.data.labels[index], 'D MMM, YYYY'), index }
            }) : [],
        }
    ]), [data, getCryptoData]);
    const stocksData = React.useMemo(() => ([
        {
            label: 'stocks',
            data: getStocksData(data) ? getStocksData(data).map((item, index) => {
                return { score: item.y, date: dayjs(item.x), index }
            }) : []
        }
    ]), [data, getStocksData]);
    const primaryAxis = React.useMemo(() => ({
        getValue: datum => datum.date.toDate(),
    }), [])
    const secondaryAxes = React.useMemo(() => [{
        getValue: datum => datum.score,
    }], [])
    useEffect(() => {
        const canvas = document.getElementsByTagName('canvas');
        [...canvas].forEach(el => el.remove())
        const pattern = trianglify({
            width,
            height: ref.current?.offsetHeight
        })
        document.body.appendChild(pattern.toCanvas())

    }, [width, ref.current?.offsetHeight])

    return (
        <AppStyled ref={ref}>
            {data ? <>
                <ChartsWrapper>
                    <Card>
                        <Header>crypto : {cryptoScore}</Header>

                        <ChartWrapper>
                            <Chart
                                options={{
                                    data: cryptoData,
                                    primaryAxis,
                                    secondaryAxes,
                                    tooltip:{show:false}
                                }}
                            />
                        </ChartWrapper>
                    </Card>
                    <Card>
                        <Header>stocks: {stocksScore}</Header>
                        <ChartWrapper>

                            <Chart
                                options={{
                                    data: stocksData,
                                    primaryAxis,
                                    secondaryAxes,
                                    tooltip:{show:false}
                                }}
                            />
                        </ChartWrapper>
                    </Card>
                </ChartsWrapper>
            </> : <div>loading</div>
            }
        </AppStyled >
    );
};

export default App;
