import { createRoot } from 'react-dom/client';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";
import App from './App';
import Rutor from './Rutor';
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<Router>
    <Routes>
        <Route path="/rutor" element={<Rutor />} />
        <Route path="/"  element={<App />} />
    </Routes>
</Router>);